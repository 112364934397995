// Import of Swiper carousel
import Swiper from "swiper/bundle";

// Init About Swiper:
export const blogSwiper = new Swiper(".blog-header .swiper", {
    // Default parameters
    loop: true,
    speed: 1000,
    slidesPerView: 1,
    centerInsufficientSlides: true,

    breakpoints: {
      // when window width is >= 768px
      992: {
        speed: 2000,
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 30,
      },
      // when window width is >= 992px
      1200: {
        speed: 3000,
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 40,
      },
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.blog-header .slider-button-next',
      prevEl: '.blog-header .slider-button-prev',
    },
  
  });