// Import of Swiper carousel
import Swiper from "swiper/bundle";

// Init the home services Swiper:
export const swiper = new Swiper(".team .swiper", {
  // Default parameters
  speed: 1000,
  slidesPerView: 1,
  spaceBetween: 10,
  centerInsufficientSlides: true,

  breakpoints: {
    // when window width is >= 992px
    992: {
      speed: 3000,
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 40,
    },
    // when window width is >= 992px
    1400: {
      speed: 3000,
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 40,
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: ".team .slider-button-next",
    prevEl: ".team .slider-button-prev",
  },
});
