// Import of Swiper carousel
import Swiper from "swiper/bundle";

// Init the home services Swiper:
export const swiper = new Swiper(".testimonials .swiper", {
  // Default parameters
  speed: 1000,
  slidesPerView: 1,
  spaceBetween: 10,
  centerInsufficientSlides: true,

  breakpoints: {
    // when window width is >= 992px
    992: {
      speed: 1000,
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 30,
    },
    // when window width is >= 1200px
    1200: {
      speed: 2000,
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 50,
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: ".testimonials .slider-button-next",
    prevEl: ".testimonials .slider-button-prev",
  },
});
