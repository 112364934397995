// Import of Swiper carousel
import Swiper from "swiper/bundle";

// Init About Swiper:
export const blogSwiper = new Swiper(".immo-header .swiper", {
    // Default parameters
    loop: true,
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 30,
    autoHeight: true,

    // breakpoints: {
    //   // when window width is >= 768px
    //   768: {
    //     speed: 2000,
    //     slidesPerView: 2,
    //     slidesPerGroup: 2,
    //     spaceBetween: 30,
    //   },
    //   // when window width is >= 992px
    //   998: {
    //     speed: 3000,
    //     slidesPerView: 4,
    //     slidesPerGroup: 4,
    //     spaceBetween: 30,
    //   },
    // },
  
    // Navigation arrows
    navigation: {
      nextEl: '.immo-header .slider-button-next',
      prevEl: '.immo-header .slider-button-prev',
    },
  
  });