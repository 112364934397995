// Import of Swiper carousel
import Swiper from "swiper/bundle";

// Init Home Swiper:
export const swiper = new Swiper(".home-header .swiper", {
  // Default parameters
  speed: 1000,
  slidesPerView: 1,
  spaceBetween: 10,
  centerInsufficientSlides: true,

  breakpoints: {
    // when window width is >= 768px
    768: {
      speed: 2000,
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 30,
    },
    // when window width is >= 992px
    992: {
      speed: 3000,
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 40,
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: ".home-header .slider-button-next",
    prevEl: ".home-header .slider-button-prev",
  },
});
